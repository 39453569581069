import React, { useState } from 'react';
import { MainNewsFragment, useMainNewsMoreLazyQuery } from '../../graphql';
import NewsMoreGrid from './NewsMoreGrid';

interface NewsMoreProps {
    endCursor?: string;
}

const NewsMore: React.FC<NewsMoreProps> = ({ endCursor }) => {
    const [news, setArticles] = useState<MainNewsFragment[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(endCursor);

    const [fetchMore] = useMainNewsMoreLazyQuery();

    const onFetchMore = async () => {
        await fetchMore({
            onCompleted: (data) => {
                setCursor(data?.news?.pageInfo?.endCursor ?? undefined);
                setArticles([...news, ...data?.news?.edges.map(edge => edge.node) || []]);
            },
            variables: {
                first: 12,
                after: cursor
            }
        });

    };

    return (
        <>
            {news && <NewsMoreGrid entries={news}/>}
            {cursor &&
                <div className="button large expanded feed-news_more" onClick={onFetchMore}>Больше новостей</div>
            }
        </>
    );
};

export default NewsMore;
