import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createPortal, render } from 'react-dom';
import Moment from 'react-moment';
import { apolloClient } from '../../graphql';
import ArticlesMore from './ArticlesMore';
import MagazineSubscribe from './MagazineSubscribe';
import NewsMore from './NewsMore';
import './styles/default.scss';
import ElecTOP from './ElecTOP';

Moment.globalLocale = 'ru';

export const MainPage = {
    init: (): void => {
        const articlesRoot = document.getElementById('articles_more');
        const newsRoot = document.getElementById('news_more');
        const elecTopRoot = document.getElementById('electop');
        const magazineSubscribe = document.getElementById('magazine_subscribe');

        render(
            <React.StrictMode>
                <ApolloProvider client={apolloClient}>
                    {articlesRoot && createPortal(
                        <ArticlesMore limit={9} endCursor={articlesRoot.dataset.cursor}/>, articlesRoot)}
                    {newsRoot && createPortal(<NewsMore endCursor={newsRoot.dataset.cursor}/>, newsRoot)}
                    {elecTopRoot && createPortal(<ElecTOP limit={10}/>, elecTopRoot)}
                    {magazineSubscribe && createPortal(<MagazineSubscribe/>, magazineSubscribe)}
                </ApolloProvider>
            </React.StrictMode>,
            document.getElementById('root')
        );
    }
};
