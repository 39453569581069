import React from 'react';
import { Cell, Grid, GutterTypes } from 'react-foundation';
import Moment from 'react-moment';
import { MainNewsFragment } from '../../graphql';

interface NewsMoreGridProps {
    entries: MainNewsFragment[];
}

type EntriesGroups = MainNewsFragment[][];

const NewsMoreGrid: React.FC<NewsMoreGridProps> = ({ entries }) => {
    if (!entries.length) {
        return null;
    }

    const entriesGroups = entries.reduce<EntriesGroups>(
        (acc, news, index) => {
            if (index % 3 === 0) {
                acc.push([]);
            }
            acc[acc.length - 1].push(news);
            return acc;
        }, []);

    return (
        <Grid upOnMedium={2} gutters={GutterTypes.MARGIN} className="large-up-4 feed-news">
            {entriesGroups.map((group, groupIndex) => (
                <Cell key={groupIndex} className="large-3">
                    {group.map((news, index) => (
                        <a key={news.id} href={news.url}>
                            <div className="feed-news_item">
                                {
                                    ((groupIndex % 2 === 0 && index === 0) || (groupIndex % 2 === 1 && index === 2)) && (
                                        <div className="image">
                                            <img src={news.image?.previewUrl} alt={news.image?.alt}/>
                                        </div>
                                    )
                                }
                                <div className="info">
                                    <div className="date">
                                        <Moment format="DD MMM YYYY г.">{news.publishedAt}</Moment>
                                    </div>
                                    <div className="rubric crop">
                                        {news.rubrica?.map(rubric => rubric?.short).join(', ')}
                                    </div>
                                </div>
                                <div className="title crop">{news.title}</div>
                            </div>
                        </a>
                    ))}
                </Cell>
            ))}
        </Grid>
    );
};
NewsMoreGrid.displayName = 'NewsMoreGrid';

export default NewsMoreGrid;
