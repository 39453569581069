import React, { useCallback, useEffect } from 'react';

const Style = {
    overlayRequired: { display: 'block' },
    revealRequired: { display: 'block' }
};

interface ModalProps {
    open: boolean,
    isModal?: boolean,
    hideCloseButton?: boolean,
    size?: 'tiny' | 'small' | 'large' | 'full',
    overlayStyle?: React.CSSProperties,
    revealStyle?: React.CSSProperties,
    closeStyle?: React.CSSProperties,
    children: React.ReactNode;
    onClose?: () => void,
}

const Modal: React.FC<ModalProps> = (
    {
        open = false,
        isModal = false,
        hideCloseButton = false,
        size = 'small',
        overlayStyle = {},
        revealStyle = {},
        closeStyle = {},
        onClose,
        children
    }) => {

    const handleCloseButton = (): void => {
        if (onClose !== undefined) {
            onClose();
        }
    };

    const closeModal = (): void => {
        if (!isModal) {
            handleCloseButton();
        }
    };

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    return (
        <>
            {open &&
            <div className="reveal-overlay" style={Object.assign({}, overlayStyle, Style.overlayRequired)} onClick={closeModal}>
                <div className={`reveal ${size}`} style={Object.assign({}, revealStyle, Style.revealRequired)}>
                    {!hideCloseButton &&
                        <button className="close-button" style={closeStyle} type="button" onClick={handleCloseButton}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    }
                    {children}
                </div>
            </div>
            }
        </>
    );
};

export default Modal;
