import React from 'react';
import { Cell, Grid, GutterTypes } from 'react-foundation';
import { MainArticleFragment } from '../../graphql';

interface ArticlesMoreGridProps {
    entries: MainArticleFragment[];
}

const groupCssClass  = ['', 'business', 'tech', 'diy'];

const ArticlesMoreGrid: React.FC<ArticlesMoreGridProps> = ({ entries }) => {
    if (!entries.length) {
        return null;
    }

    return (
        <Grid upOnMedium={2} gutters={GutterTypes.MARGIN} className="large-up-3">
            {
                entries.map(article =>
                    <Cell key={article.id}>
                        <a href={article.url}>
                            <div className="article">
                                {article.image?.previewUrl &&
                                    <div className="img">
                                        <img src={article.image.previewUrl} alt={article.image.alt}/>
                                    </div>
                                }
                                <div className="content">
                                    <div className="rubric">
                                        <span className={groupCssClass[article.rubrica?.groupId ?? 0]}>
                                            {article.rubrica?.groupName ?? ''}
                                        </span>
                                    </div>
                                    <div className="title crop">{article.title}</div>
                                    <div className="desc crop">{article.tizer}</div>
                                </div>
                            </div>
                        </a>
                    </Cell>
                )
            }
        </Grid>
    );
};
ArticlesMoreGrid.displayName = 'ArticlesMoreGrid';

export default ArticlesMoreGrid;
