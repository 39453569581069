import clsx from 'clsx';
import React, { useState } from 'react';
import { Button, Cell, Grid, GutterTypes } from 'react-foundation';
import { useForm } from 'react-hook-form';
import { MagazineSubscriptionVariantEnum, useMagazineSubscriptionCreateMutation } from '../../graphql';
import Modal from '../../shared/Modal';

export type MagazineSubscribeData = {
    name: string
    email: string;
    phone: string;
    company: string;
    appointment: string;
    variant: MagazineSubscriptionVariantEnum;
    isAgree: boolean;
}

const MagazineSubscribe: React.FC = () => {
    const [magazineSubscription] = useMagazineSubscriptionCreateMutation();
    const [open, setOpen] = useState(false);

    const {
        register: registerForm,
        handleSubmit: handleSubmitForm,
        formState: { errors: errorsForm, isDirty: isDirtyForm, isValid: isValidForm },
        setError: setErrorForm,
        reset: resetForm
    } = useForm<MagazineSubscribeData>();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    const handleSubscription = handleSubmitForm(async (formData: MagazineSubscribeData) => {
        const { data } = await magazineSubscription({
            variables: {
                input: {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    company: formData.company,
                    appointment: formData.appointment,
                    variant: formData.variant,
                    isAgree: formData.isAgree
                }
            }
        });

        if (data?.magazineSubscriptionCreate?.errors.length) {
            data.magazineSubscriptionCreate.errors.forEach(e => {
                if (e.field === 'name') {
                    setErrorForm('name', { message: e.message ?? '' });
                } else if (e.field === 'email') {
                    setErrorForm('email', { message: e.message ?? '' });
                } else if (e.field === 'phone') {
                    setErrorForm('phone', { message: e.message ?? '' });
                } else if (e.field === 'company') {
                    setErrorForm('company', { message: e.message ?? '' });
                } else if (e.field === 'appointment') {
                    setErrorForm('appointment', { message: e.message ?? '' });
                } else {
                    console.error('MagazineSubscribeForm error:', e);
                }
            });
            return;
        }

        handleClose();
    });

    return (
        <>
            <Button isExpanded className="san-marino" onClick={handleOpen}>Подписаться</Button>
            <Modal open={open} onClose={handleClose} isModal={true}>
                <h3>Подписка на журнал «Электротехнический рынок»</h3>
                <form noValidate onSubmit={handleSubscription}>
                    <Grid vertical>
                        <Cell>
                            <label className={clsx({ 'is-invalid-label': errorsForm.name })}>
                                ФИО
                                <input type="text"
                                    className={clsx({ 'is-invalid-input': errorsForm.name })}
                                    placeholder="Обязательное поле"
                                    {...registerForm('name',
                                        { required: 'Это поле не может быть пустым.' })}
                                />
                            </label>
                            {errorsForm.name &&
                                <div className="form-error is-visible">{errorsForm.name.message}</div>}
                        </Cell>
                        <Cell>
                            <Grid gutters={GutterTypes.MARGIN}>
                                <Cell small={6}>
                                    <label className={clsx({ 'is-invalid-label': errorsForm.email })}>
                                        Почта (e-mail)*
                                        <input type="email"
                                            className={clsx({ 'is-invalid-input': errorsForm.email })}
                                            placeholder="Обязательное поле"
                                            {...registerForm('email',
                                                { required: 'Это поле не может быть пустым.' })}
                                        />
                                    </label>
                                    {errorsForm.email &&
                                        <div className="form-error is-visible">{errorsForm.email.message}</div>}
                                </Cell>
                                <Cell small={6}>
                                    <label className={clsx({ 'is-invalid-label': errorsForm.phone })}>
                                        Телефон
                                        <input type="text"
                                            className={clsx({ 'is-invalid-input': errorsForm.phone })}
                                            placeholder="+7 (___) ___-__-__"
                                            {...registerForm('phone')}/>
                                    </label>
                                    {errorsForm.phone &&
                                        <div className="form-error is-visible">{errorsForm.phone.message}</div>}
                                </Cell>
                            </Grid>
                        </Cell>
                        <Cell>
                            <Grid gutters={GutterTypes.MARGIN}>
                                <Cell small={6}>
                                    <label className={clsx({ 'is-invalid-label': errorsForm.company })}>
                                        Компания
                                        <input type="text"
                                            className={clsx({ 'is-invalid-input': errorsForm.company })}
                                            {...registerForm('company')}/>
                                    </label>
                                    {errorsForm.company &&
                                        <div className="form-error is-visible">{errorsForm.company.message}</div>}
                                </Cell>
                                <Cell small={6}>
                                    <label className={clsx({ 'is-invalid-label': errorsForm.appointment })}>
                                        Должность
                                        <input type="text"
                                            className={clsx({ 'is-invalid-input': errorsForm.appointment })}
                                            {...registerForm('appointment')}/>
                                    </label>
                                    {errorsForm.appointment &&
                                        <div className="form-error is-visible">{errorsForm.appointment.message}</div>}
                                </Cell>
                            </Grid>
                        </Cell>
                        <Cell>
                            <label className={clsx({ 'is-invalid-label': errorsForm.variant })}>
                                Вариант подписки
                                <select {...registerForm('variant', { required: 'Обязательное поле.' })}>
                                    <option disabled>Выберите один из вариантов</option>
                                    <option value={MagazineSubscriptionVariantEnum.EMAIL}>Электронная версия</option>
                                    <option value={MagazineSubscriptionVariantEnum.POST}>Печатная версия</option>
                                </select>
                            </label>
                            {errorsForm.variant &&
                                <div className="form-error is-visible">{errorsForm.variant.message}</div>}
                        </Cell>
                        <Cell>
                            <input type="checkbox" id="id_is_agree"
                                className={clsx({ 'is-invalid-input': errorsForm.isAgree })}
                                {...registerForm('isAgree', { required: 'Обязательное поле.' })}/>
                            <label htmlFor="id_is_agree" className={clsx({ 'is-invalid-label': errorsForm.isAgree })}>
                                Принимаю условия обработки персональных данных
                            </label>
                        </Cell>
                        <Cell className="form-actions">
                            <Button isDisabled={!isValidForm || !isDirtyForm} className="san-marino">Подписаться</Button>
                        </Cell>
                    </Grid>
                </form>
            </Modal>
        </>
    );
};

export default MagazineSubscribe;
