import React, { useState } from 'react';
import { MainArticleFragment, useMainArticlesMoreLazyQuery } from '../../graphql';
import ArticlesMoreGrid from './ArticlesMoreGrid';

interface ArticlesMoreProps {
    limit: number;
    endCursor?: string;
}

const ArticlesMore: React.FC<ArticlesMoreProps> = ({ limit, endCursor }) => {
    const [articles, setArticles] = useState<MainArticleFragment[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(endCursor);

    const [fetchMore] = useMainArticlesMoreLazyQuery();

    const onFetchMore = async () => {
        await fetchMore({
            onCompleted: (data) => {
                setCursor(data?.articles?.pageInfo?.endCursor ?? undefined);
                setArticles([...articles, ...data?.articles?.edges.map(edge => edge.node) || []]);
            },
            variables: {
                first: limit,
                after: cursor
            }
        });

    };

    return (
        <>
            {articles && <ArticlesMoreGrid entries={articles}/>}
            {cursor &&
                <div className="article_more">
                    <div className="button large expanded more" onClick={onFetchMore}>Показать еще</div>
                </div>
            }
        </>
    );
};

export default ArticlesMore;
