import { clsx } from 'clsx';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ELECTOP_API_URL } from './const';
import { ElecTOPSite, ElecTOPSiteResponse } from './types';


interface ElecTOPProps {
    limit: number;
}

const ElecTOP: React.FC<ElecTOPProps> = ({ limit }) => {
    const { ref, inView } = useInView();
    const [sites, setSites] = useState<ElecTOPSiteResponse>({} as ElecTOPSiteResponse);

    useEffect(() => {
        if (inView) {
            fetchSites(limit).then(result => setSites(result.data)).catch(() => {});
        }

    }, [inView]);

    return (
        <div ref={ref}>
            {Object.entries(sites).map(([key, value]: [string, ElecTOPSite]) => {
                return (
                    <div key={key} className="electop_item">
                        <div className="num">{key}</div>
                        <div className="item">
                            <div className="name crop">{value.name}</div>
                            <div className="site">
                                <a href={value.url} target="_blank" rel="nofollow noreferrer">{value.url}</a>
                            </div>
                        </div>
                        <div className="views">
                            <div className="count">{value.hits}</div>
                            <div className={clsx('change', value.hits_delta > 0 ? 'up' : 'down')}>{value.hits_delta > 0 && '+'}{value.hits_delta}</div>
                        </div>
                        <div className="visitors">
                            <div className="count">{value.hostsuniq}</div>
                            <div className={clsx('change', value.hostsuniq_delta > 0 ? 'up' : 'down')}>{value.hostsuniq_delta > 0 && '+'}{value.hostsuniq_delta}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const fetchSites = async (limit: number): Promise<AxiosResponse<ElecTOPSiteResponse>> => {
    return await axios.get<ElecTOPSiteResponse>(`${ELECTOP_API_URL}?limit=${limit}&v=2`);
};

export default ElecTOP;
